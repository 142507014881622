<template>
    <header>
		<div class="gl-header">
			<nav class="navbar navbar-fixed-top">
				<div class="container">
					<div id="site-logo" class="navbar-header">
						<button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar" @click="showMenu">
							<span class="sr-only">Toggle navigation</span>
							<span class="icon-bar"></span>
							<span class="icon-bar"></span>
							<span class="icon-bar"></span>
						</button>
						<a class="navbar-brand" href="/" title="back to home page">
							<img src="../assets/images/gl-logo.png" alt="PREMIUM JOKER">
						</a>
					</div>
					<div :class="{'navbar-collapse collapse': !showmenu }">
						<ul class="nav navbar-nav navbar-right">
							<li><a href="/" target="_parent">PREMIUM JOKER</a></li>
							<li><a href="https://lin.ee/lgMF2P8" target="_blank">สมัครสมาชิก</a></li>
							<li><a href="/promotion" target="_parent">โปรโมชั่น</a></li>
							<li><a href="/games" target="_blank">เข้าเล่น</a></li>
							<li><a href="/playdemo" target="_parent">ทดลองเล่นเกมฟรี</a></li>
							<li><a href="https://lin.ee/lgMF2P8" target="_blank">ติดต่อเรา</a></li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
	</header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
        showmenu: false,
    }
  },
  methods: {
      showMenu(){
          if(!this.showmenu){
              this.showmenu = true;
          }else{
              this.showmenu = false;
          }
      }
  }
}
</script>